import {singleModule} from "./components/Public";

let siteKey;

const INTERVAL         = 7200000;
const SERVICE_MISSING  = 'stub-service-missing';
const SERVICE_DISABLED = 'stub-service-disabled';

function getStub() {
    let stub = {
        callback: null,
        ready: function (callback) {
            stub.callback = callback;
        },
        execute: function () {
            return {
                then: function (request) {
                    request(siteKey === '' ? SERVICE_DISABLED : SERVICE_MISSING);
                }
            }
        }
    };

    return stub;
}

document.addEventListener('DOMContentLoaded', function (){
    singleModule('cookieConfirm', true);

    let stamp    = new Date(),
        token    = document.head.querySelector('meta[name="site-key"]'),
        buttons  = document.querySelectorAll('.form-submit'),
        gr, cForm, left, index, handler;

        siteKey = token.content;
        gr      = window.grecaptcha ? window.grecaptcha : getStub();

        document.head.removeChild(token);

        handler = setTimeout(function () {
            location.reload();
        }, INTERVAL);

        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
                left = (new Date()) - stamp;

                if (left > INTERVAL) {
                    location.reload();
                }

                handler = setTimeout(function () {
                    location.reload();
                }, left);
            } else {
                clearTimeout(handler);
            }
        });

        for (index in buttons)
            if (buttons.hasOwnProperty(index)) {
                cForm = document.querySelector('form[name="' + buttons[index].getAttribute('form') + '"]');

                (function (form, button) {
                    let inputs = form.querySelectorAll('input'),
                        iKey;

                    for (iKey in inputs)
                        if (inputs.hasOwnProperty(iKey)) {
                            inputs[iKey].addEventListener('keypress', function (e) {
                                if (e.keyCode === 13) {
                                    e.preventDefault();

                                    button.click();
                                }
                            });
                        }
                }) (cForm, buttons[index]);

                buttons[index].addEventListener('click', function (e) {
                    e.preventDefault();

                    let formName = e.currentTarget.getAttribute('form'),
                        form     = document.querySelector('form[name="' + formName + '"]');

                    gr.ready(function() {
                        gr.execute(siteKey, {action: 'submit_' + formName}).then(function(token) {
                            let input = form.appendChild(document.createElement('INPUT'));

                            input.type  = 'hidden';
                            input.name  = 'rToken';
                            input.value = token;

                            form.submit();
                        });
                    });
                });
            }
});
